<template>
  <div>
    <v-dialog v-model="dialog"
              width="600"
              persistent
              scrollable>

      <v-card :loading="btnLoading || refreshPointsLoading"
              :disabled="btnLoading || refreshPointsLoading">
        <v-card-title>
          {{ form.id ? 'Modifier la conversion' : 'Ajouter une conversion' }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-4">

          <div v-if="statistic.salepoint_total === 0 && !form.id">
            <v-alert type="warning" prominent text>
              Impossible de créer une nouvelle conversion, car le total pos ganiant est égal à zéro
            </v-alert>
          </div>

          <form @submit.prevent="save">

            <v-text-field label="Description *"
                          @input="errors && errors.description ? errors.description = '' : false"
                          :error-messages="errors.description"
                          v-model="form.description"/>

            <v-text-field label="Points de conversion maximum"
                          hide-details="auto"
                          :disabled="!!form.id"
                          v-number
                          @input="errors && errors.max_points ? errors.max_points = '' : false"
                          :error-messages="errors.max_points"
                          v-model="form.max_points"/>


            <div v-if="!form.id">

              <v-row justify="center" align="center">
                <v-col cols="6" class="text-left">
                  <v-switch v-model="with_filter" label="Avec filtrage des pos ?"></v-switch>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn text color="primary"
                         :loading="refreshPointsLoading"
                         @click="refreshPoints"
                         v-if="!with_filter"
                         :disabled="!form.max_points">
                    <v-icon left>mdi-reload</v-icon>
                    Vérifier
                  </v-btn>
                </v-col>
              </v-row>

              <div v-if="!with_filter">
                <v-alert type="info" text prominent>
                  <div class="d-block">
                    <v-icon color="#2196f3" class="mt--4">mdi-map-marker-outline</v-icon>
                    Total des POS qui gagnants :
                    <v-chip color="info" small>
                      {{ statistic.salepoint_total }}
                    </v-chip>
                  </div>
                  <div class="d-block mt-4">
                    <v-icon color="#2196f3" class="mt--4">mdi-currency-usd</v-icon>
                    Total points gagnants :
                    <v-chip color="info" small outlined>
                      {{ statistic.winning_points_total }}
                    </v-chip>
                  </div>
                </v-alert>
              </div>

              <v-progress-linear
                  v-if="with_filter"
                  :indeterminate="filterLoading"/>

              <div class="pa-4"
                   :class="$vuetify.theme.dark?'grey':'grey lighten-3'"
                   v-if="with_filter">

                <h3>
                  <v-icon>mdi-filter-outline</v-icon>
                  Filtrer les points de vente
                </h3>

                <v-divider class="my-4"/>

                <v-row>

                  <v-col cols="12" lg="6">
                    <v-select
                        :items="filter_elements.types"
                        v-model="filter.types"
                        item-text="name"
                        item-value="name"
                        multiple
                        chips
                        small-chips
                        deletable-chips
                        dense
                        filled
                        hide-details
                        id="key_1"
                        :label="keyword.key_1"
                        rounded
                        class="rounded"/>
                  </v-col>

                  <v-col cols="12" lg="6">
                    <v-autocomplete
                        :items="cities"
                        v-model="filter.cities"
                        item-text="name"
                        item-value="name"
                        multiple
                        chips
                        small-chips
                        deletable-chips
                        dense
                        filled
                        hide-details
                        id="key_3"
                        :label="keyword.key_3"
                        rounded
                        class="rounded">
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggle">
                          <v-list-item-action>
                            <v-icon :color="filter.cities.length > 0 ? 'primary' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Sélectionner tout
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" lg="6">
                    <v-select
                        :items="filter_elements.regions"
                        v-model="filter.regions"
                        item-text="name"
                        item-value="name"
                        multiple
                        chips
                        small-chips
                        deletable-chips
                        dense
                        filled
                        hide-details
                        id="key_2"
                        :label="keyword.key_2"
                        rounded
                        class="rounded"/>
                  </v-col>

                  <v-col cols="12" lg="6">
                    <v-select
                        :items="filter_elements.municipalities"
                        v-model="filter.municipalities"
                        item-text="name"
                        item-value="name"
                        multiple
                        chips
                        small-chips
                        deletable-chips
                        dense
                        filled
                        hide-details
                        id="key_4"
                        :label="keyword.key_4"
                        rounded
                        class="rounded"/>
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn @click="onFilter"
                           depressed
                           color="blue"
                           :loading="filterLoading"
                           dark>
                      <v-icon left>mdi-filter-outline</v-icon>
                      Filtrer
                    </v-btn>
                  </v-col>

                </v-row>

                <v-alert type="info" text prominent class="mt-4">

                  <p>
                    <strong>Remarque :</strong>
                    Ceci est le résultat de l'ajout du filtre ci-dessus
                  </p>

                  <div class="d-block">
                    <v-icon color="#2196f3" class="mt--4">mdi-map-marker-outline</v-icon>
                    Total des POS qui gagnants :
                    <v-chip color="info" small>{{ filter_data.filter_salepoint_total }}</v-chip>
                  </div>

                  <div class="d-block mt-4">
                    <v-icon color="#2196f3" class="mt--4">mdi-currency-usd</v-icon>
                    Total points gagnants :
                    <v-chip color="info" small outlined>
                      {{ filter_data.filter_winning_points_total }}
                    </v-chip>
                  </div>

                </v-alert>

              </div>

            </div>

            <div class="d-flex justify-space-between mt-3">
              <v-spacer/>
              <v-btn color="primary"
                     :loading="btnLoading"
                     depressed
                     :disabled="with_filter && filter_data.filter_salepoint_total === 0 || statistic.salepoint_total === 0 && !form.id"
                     type="submit">
                <v-icon left>mdi-content-save</v-icon>
                Enregistrer
              </v-btn>
            </div>

          </form>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['dialog', 'statistic'],
  data() {
    return {
      filterLoading: false,
      with_filter: false,
      btnLoading: false,
      refreshPointsLoading: false,
      errors: {},
      form: {
        id: '',
        description: '',
        max_points: '',
      },
      filter: {
        cities: [],
      },
      filter_data: {
        filter_salepoint_total: 0,
        filter_winning_points_total: 0,
        filter_salepoint_account_ids: [],
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    edit(item) {
      this.form.id = item.id
      this.form.description = item.description
      this.form.max_points = item.max_points
    },
    add() {
      this.form.id = ''
      this.form.description = ''
      this.form.max_points = ''
      this.errors = {}
    },
    save() {
      this.btnLoading = true
      this.$Progress.start()
      const url = this.form.id ? '/transactions/update' : '/transactions/store'
      let data = this.form
      data['with_filter'] = this.with_filter
      data['filter_data'] = this.with_filter ? this.filter_data : ''
      HTTP.post(url, data).then(() => {
        this.$successMessage = 'Cette transaction a été ajouteé avec succeès'
        this.close()
        this.add()
        this.$emit('get-transactions')
        this.btnLoading = false
        this.$Progress.finish()
      }).catch(err => {
        this.btnLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    },
    toggle() {
      if (this.filter.cities.length === this.cities.length) {
        this.filter.cities = []
      } else {
        this.filter.cities = this.cities.map(el => el.name)
      }
    },
    onFilter() {
      this.filterLoading = true
      this.$Progress.start()
      HTTP.get('/transactions/filter-salepoints', {
        params: {
          filter: this.filter,
          max_points: this.form.max_points,
        }
      }).then(res => {
        this.filterLoading = false
        this.filter_data = res.data.data
        this.$Progress.finish()
      }).catch(err => {
        this.filterLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
    refreshPoints() {
      this.refreshPointsLoading = true
      this.$Progress.start()
      HTTP.get('/transactions/refresh-points', {
        params: {
          max_points: this.form.max_points
        }
      }).then((res) => {
        this.refreshPointsLoading = false
        this.$Progress.finish()
        let new_statistic = {
          transactions_total: this.statistic.transactions_total,
          salepoint_total: res.data.statistic.salepoint_total,
          winning_points_total: res.data.statistic.winning_points_total,
        }
        this.$emit('update:statistic', new_statistic)
      }).catch(err => {
        this.refreshPointsLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
  },
  computed: {
    filter_elements() {
      return this.$store.state.salepoint_filter_elements
    },
    cities() {
      return this.$store.state.cities
    },
    keyword() {
      return this.$store.state.keyword
    },
    icon() {
      if (this.filter.cities.length === this.cities.length) return 'mdi-close-box'
      if (this.filter.cities.length > 0 && this.filter.cities.length !== this.cities.length) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>

<style scoped>

</style>