<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-row>

        <v-col lg="3" cols="12">
          <Statistics :statistic="statistic"
                      @newconversion="addItem"
                      ref="statistics"
                      :years="years"
                      :loading="loading"/>
        </v-col>

        <v-col lg="9" cols="12">
          <v-card class="rounded-lg shadow" :loading="overlay" :disabled="overlay">
            <v-card-title>
              <div>
                <v-text-field dense filled rounded
                              clearable
                              v-model="search"
                              append-icon="mdi-magnify"
                              placeholder="Recherche..."
                              class="w-300"
                              single-line hide-details></v-text-field>
              </div>
              <v-spacer/>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon
                         @click="refresh">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon
                         @click="ExportExcelTransactions">
                    <v-icon>mdi-cloud-download-outline</v-icon>
                  </v-btn>
                </template>
                <span>Exporter</span>
              </v-tooltip>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on"
                         @click="[drawer = true]"
                         icon>
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <span>Filtrer</span>
              </v-tooltip>
              <v-btn color="blue" dark class="ml-1" fab small depressed
                     @click="addItem">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text v-if="loading && transactions.length === 0">
              <v-skeleton-loader type="table"/>
            </v-card-text>

            <v-card-text v-if="!loading && transactions.length ===0"
                         class="text-center">
              <NoResult/>
            </v-card-text>

            <div v-if="!loading && transactions.length>0">
              <v-data-table
                  :headers="headers"
                  :items="transactions"
                  :items-per-page="5"
                  :search="search"
              >

                <template v-slot:item.max_points="{item}">
                  <v-chip v-if="item.max_points" dark color="blue" small>
                    {{ item.max_points | toCurrency }}
                  </v-chip>
                  <v-chip v-else dark color="blue" small>
                    Tous
                  </v-chip>
                </template>

                <template v-slot:item.sum_points="{item}">
                  <v-chip dark color="primary" small>
                    {{ parseInt(item.sum_points) | toCurrency }}
                  </v-chip>
                </template>

                <template v-slot:item.salepoint_accounts_count="{item}">
                  <v-chip dark color="success" small
                          @click="openSalepointsDialog(item.id)">
                    <v-icon small left>mdi-map-marker-outline</v-icon>
                    {{ item.success_transactions }} / {{ item.salepoint_accounts_count }}
                  </v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on" icon
                               @click="ExportExcelTransactionSalepoints(item.id)">
                          <v-icon size="20">mdi-cloud-download-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Exporter</span>
                    </v-tooltip>
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on" icon
                               @click="editItem(item)">
                          <v-icon size="20">mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Modifier</span>
                    </v-tooltip>
                  </div>
                </template>

              </v-data-table>
            </div>

          </v-card>
        </v-col>
      </v-row>
      <!--Dialogs && Drawer-->

      <TransactionFormDialog
          :dialog.sync="transactionFormDialog"
          @get-transactions="getTransactions"
          :statistic.sync="statistic"
          ref="transactionFormDialog"/>

      <SalepointsDialog
          :dialog.sync="salepointsDialog"
          ref="salepointsDialog"/>

      <v-navigation-drawer
          :permanent="drawer"
          clipped v-model="drawer"
          width="400"
          floating
          app
          right
          temporary>
        <v-list-item>
          <v-list-item-avatar color="blue">
            <v-icon dark>mdi-tune</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Filtrages</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
        <FilterDrawer @filter="[getTransactions($event),drawer=false]" ref="FilterDrawer"/>
      </v-navigation-drawer>

      <!--Dialogs && Drawer-->
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import Statistics from "./Statistics";
import FilterDrawer from "./FilterDrawer";
import NoResult from "../../components/NoResult";
import TransactionFormDialog from "./Dialogs/TransactionFormDialog";
import SalepointsDialog from "./Dialogs/SalepointsDialog";

export default {
  components: {TransactionFormDialog, NoResult, Statistics, SalepointsDialog, FilterDrawer},
  data() {
    return {
      headers: [
        {text: 'Description', value: 'description'},
        {text: 'Points max', align: 'center', value: 'max_points'},
        {text: 'Somme des points', align: 'center', value: 'sum_points'},
        {text: 'Total POS', align: 'center', value: 'salepoint_accounts_count'},
        {text: 'Date', value: 'date'},
        {text: 'Actions', value: 'actions'},
      ],
      transactions: [],
      years: [],
      loading: false,
      drawer: false,
      overlay: false,
      transactionFormDialog: false,
      search: '',
      statistic: {
        transactions_total: 0,
        salepoint_total: 0,
        winning_points_total: 0
      },
      salepointsDialog: false,
      filter: {}
    }
  },
  methods: {
    getTransactions(filter) {
      this.transactions = []
      this.loading = true
      this.$Progress.start()
      this.filter = filter ? filter : this.filter
      HTTP.get('/transactions', {
        params: {
          filter: this.filter
        }
      }).then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.transactions = res.data.data
        this.statistic = res.data.statistic
        this.years = res.data.years
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    addItem() {
      this.transactionFormDialog = true
      this.$refs.transactionFormDialog.add()
    },
    editItem(item) {
      this.transactionFormDialog = true
      this.$refs.transactionFormDialog.edit(item)
    },
    openSalepointsDialog(id) {
      this.salepointsDialog = true
      this.$refs.salepointsDialog.getSalepointsByTransaction(id, 1, 10)
    },
    ExportExcelTransactions() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/transactions?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
    ExportExcelTransactionSalepoints(id) {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/transaction-salepoints?token=' + token + '&id=' + userId + '&transaction_id=' + id;
      window.open(DownloadURL, '_blanc')
    },
    refresh() {
      this.filter = {}
      this.getTransactions()
      this.$refs.statistics.getTransactionsAnalyticByMonthYear()
    },
  },
  created() {
    this.getTransactions()
  },
}
</script>

<style scoped>

</style>