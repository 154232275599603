<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="1100">

      <v-card :disabled="sendBackLoading"
              :loading="sendBackLoading">

        <v-card-title>
          Tous les POS
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text>

          <div class="d-flex justify-space-between my-4">
            <div>
              <v-text-field v-model="keyword"
                            append-icon="mdi-magnify"
                            class="w-300"
                            clearable
                            dense
                            filled
                            hide-details
                            placeholder="Recherche..."
                            rounded single-line></v-text-field>
            </div>

            <div :style="{width : '200px'}">
              <v-select
                      v-model="status"
                      :items="statuses"
                      chips
                      class="ml-1"
                      dense
                      filled
                      hide-details
                      item-text="text"
                      item-value="value"
                      placeholder="Statut"
                      rounded
                      small-chips>
                <template v-slot:selection="data">
                  <v-chip :color="data.item.color" dark small>
                    {{ data.item.text }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar size="10">
                    <v-icon :color="data.item.color">mdi-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </div>

            <div class="mt-1 ml-1">
              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>
            </div>

            <v-spacer/>

            <v-btn color="primary"
                   depressed
                   @click="ExportExcel(id)">
              <v-icon left>mdi-cloud-download-outline</v-icon>
              Exporter
            </v-btn>

          </div>

          <v-skeleton-loader v-if="loading" type="table"/>

          <div v-if="!loading && salepoints.length > 0">

            <div class="mb-4 table-border pa-3">

              <span v-if="status === 'all'">
                Total :
                 <v-chip color="blue" dark small>
                  {{ statistic.all }}
                </v-chip>
              </span>

              <span v-if="status === 'all' || status === ''" class="ml-6">
                Total conversions (En attente) :
                <v-chip color="orange" dark small>
                  {{ statistic.waiting }}
                </v-chip>
              </span>

              <span v-if="status === 'all' || status === 'error'" class="ml-6">
                Total conversions (Erreur) :
                 <v-chip color="error" dark small>
                   {{ statistic.error }}
                 </v-chip>
              </span>

              <span v-if="status === 'all' || status === 'success'" class="ml-6">
                Total conversions (Succès) :
                 <v-chip color="success" dark small>
                   {{ statistic.success }}
                 </v-chip>
              </span>

            </div>

            <v-simple-table class="table-border">
              <template v-slot:default>
                <thead>
                <tr>
                  <th>Photo</th>
                  <th id="key_26">{{ keywords.key_26 }}</th>
                  <th id="key_27">{{ keywords.key_27 }}</th>
                  <th id="key_28">{{ keywords.key_28 }}</th>
                  <th>Téléphone-APP</th>
                  <th>
                    <span id="key_3">{{ keywords.key_3 }}</span>
                  </th>
                  <th class="text-center">Points de conversion</th>
                  <th class="text-center">Statut</th>
                  <th class="text-right">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in salepoints" :key="item.id">

                  <td>
                    <v-avatar size="38">
                      <v-img v-if="item.salepoint_account && item.salepoint_account.photo"
                             :src="FILE_URL + item.salepoint_account.photo"></v-img>
                      <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                    </v-avatar>
                  </td>

                  <td>{{ item.code }}</td>

                  <td>{{ item.name }}</td>

                  <td>{{ item.phone }}</td>

                  <td>
                   <span v-if="item.salepoint_account && item.salepoint_account.salepoint_phone"
                         class="info--text">
                      {{ item.salepoint_account.salepoint_phone }}
                      </span>
                    <span v-else>-</span>
                  </td>

                  <td>{{ item.city }}</td>

                  <td class="text-center">
                    <v-chip color="blue" dark small>
                      {{ item.transaction_points | toCurrency }}
                    </v-chip>
                  </td>

                  <td class="text-center">
                    <v-chip :color="setStatusColor(item.transaction_status)"
                            dark
                            small>
                      <v-icon left small v-html="setStatus(item.transaction_status)['icon']"></v-icon>
                      {{ setStatus(item.transaction_status)['value'] }}
                    </v-chip>
                  </td>

                  <td class="text-right">
                    <v-tooltip color="primary" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :dark="item.transaction_status === ''"
                               :disabled="item.transaction_status !== '' && item.transaction_status !== 'error'"
                               color="primary"
                               depressed
                               fab
                               v-bind="attrs"
                               x-small
                               @click="sendBack(item.transaction_salepoint_id,item.salepoint_account_id)"
                               v-on="on">
                          <v-icon size="20">mdi-sync</v-icon>
                        </v-btn>
                      </template>
                      <span>Renvoyer</span>
                    </v-tooltip>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-card-actions>
              <div>
                <v-select v-model="per_page"
                          :items="[10,20,50,100,200,300,400,500]"
                          :style="{width: '120px'}"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined></v-select>
              </div>
              <v-spacer/>
              <v-pagination v-if="total>per_page"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="6"
                            @input="onPageChange"
              ></v-pagination>
            </v-card-actions>

          </div>

          <NoResult
                  v-if="!loading && salepoints.length === 0"
                  class="text-center"/>


        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResult from "../../../components/NoResult";

export default {
    props: ['dialog'],
    components: {
        NoResult
    },
    data() {
        return {
            FILE_URL: process.env.VUE_APP_FILE_URL,
            keywords: this.$store.state.keyword,
            loading: false,
            sendBackLoading: false,
            keyword: '',
            id: '',
            salepoints: [],
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },

            status: 'all',
            statistic: {
                'all': 0,
                'error': 0,
                'success': 0,
                'waiting': 0,
            }
        }
    },
    methods: {
        close() {
            this.$emit('update:dialog', false)
        },
        getSalepointsByTransaction(id, current_page, per_page) {
            this.pagination.current = current_page ? current_page : this.pagination.current
            this.per_page = per_page ? per_page : this.per_page
            this.id = id ? id : this.id
            this.loading = true
            this.$Progress.start()
            HTTP.get('/transactions/' + this.id + '/salepoints?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    per_page: this.per_page,
                    status: this.status,
                }
            }).then((res) => {
                this.salepoints = res.data.data.data
                this.pagination.current = res.data.data.current_page;
                this.pagination.total = res.data.data.last_page;
                this.total = res.data.data.total;
                this.statistic = res.data.statistic;
                this.loading = false
                this.$Progress.finish()
            }).catch(err => {
                this.loading = false
                this.$Progress.fail()
                console.log(err)
            })
        },
        onPageChange() {
            this.getSalepointsByTransaction()
        },
        ExportExcel(id) {
            let baseUrl = process.env.VUE_APP_FILE_URL
            let token = this.$store.state.user.token
            let userId = this.$store.state.user.id
            let DownloadURL = baseUrl + '/export/excel/transaction-salepoints?token=' + token + '&id=' + userId + '&transaction_id=' + id;
            window.open(DownloadURL, '_blanc')
        },
        refresh() {
            this.status = 'all'
            this.getSalepointsByTransaction()
        },
        sendBack(transaction_salepoint_id, salepoint_account_id) {
            this.sendBackLoading = true
            this.$Progress.start()
            let data = {
                transaction_salepoint_id,
                salepoint_account_id
            }
            HTTP.post('/transactions/send-back', data).then(() => {
                this.sendBackLoading = false
                this.$successMessage = 'Renvoyé avec succès'
                this.$Progress.finish()
                this.updateStatus(salepoint_account_id)
            }).catch(err => {
                this.sendBackLoading = false
                this.$errorMessage = err.response.data.message
                if (err.response.data && err.response.data.data === 'done') {
                    this.updateStatus(salepoint_account_id)
                }
                this.$Progress.fail()
                console.log(err)
            })

        },

        updateStatus(salepoint_account_id) {
            let index = this.salepoints.findIndex(el => el.salepoint_account_id === salepoint_account_id)

            if (this.salepoints[index].transaction_status === null) {
                this.statistic.waiting -= 1
            }

            if (this.salepoints[index].transaction_status === 'error') {
                this.statistic.error -= 1
            }

            this.salepoints[index].transaction_status = 'success'
            this.statistic.success += 1
        },

        setStatusColor(status) {
            switch (status) {
                case '' :
                    return 'orange';
                case 'error' :
                    return 'error';
                case 'success' :
                    return 'success';
            }
        },
        setStatus(status) {
            switch (status) {
                case '' :
                    return {
                        'icon': 'mdi-timer-sand',
                        'value': 'En attente',
                    };
                case 'error' :
                    return {
                        'icon': 'mdi-close',
                        'value': 'Erreur',
                    };
                case 'success' :
                    return {
                        'icon': 'mdi-check',
                        'value': 'Succès',
                    };
            }
        },
    },
    watch: {
        keyword() {
            this.getSalepointsByTransaction();
        },
        per_page() {
            this.pagination.current = 1
            this.getSalepointsByTransaction();
        },
        status() {
            this.getSalepointsByTransaction();
        },
    },
    computed: {
        statuses() {
            return [
                {'color': 'blue', 'value': 'all', 'text': 'Tous'},
                {'color': 'orange', 'value': '', 'text': 'En attente'},
                {'color': 'error', 'value': 'error', 'text': 'Erreur'},
                {'color': 'success', 'value': 'success', 'text': 'Succès'},
            ]
        }
    }
}
</script>

<style scoped>

</style>