import { render, staticRenderFns } from "./SalepointsDialog.vue?vue&type=template&id=64b96a08&scoped=true&"
import script from "./SalepointsDialog.vue?vue&type=script&lang=js&"
export * from "./SalepointsDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b96a08",
  null
  
)

export default component.exports